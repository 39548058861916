<template>
    <div class="search">
        <input
            type="text"
            v-model="searchText"
            :name="name"
            :placeholder="searchPlaceholder"
            @keyup.enter="search"
            @input="automaticSearch"
        />
        <div class="cross-icon" v-if="searchText" @click="eraseSearch">
            <div>
                <i class="fas fa-times-circle"></i>
            </div>
        </div>

        <div class="search-icon" @click="search">
            <div>
                <i class="fas fa-search"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce as _debounce } from "lodash"
export default {
    name: "BasicSearch",
    data() {
        return {
            searchText: "",
            timeout: null,
        }
    },
    emits: ["search"],
    props: {
        searchPlaceholder: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        modelValue: {
            type: String,
            default: "",
        },
    },

    watch: {
        modelValue: {
            immediate: true,
            handler(newVal) {
                this.searchText = newVal
            },
        },
    },

    methods: {
        automaticSearch: _debounce(function () {
            // Annulez toute action planifiée précédemment chaque fois que l'utilisateur tape un caractère.
            if (this.searchText.length >= 3) {
                this.searchText.trim()
                this.$emit("search", this.searchText)
            } else if (this.searchText === "") {
                this.$emit("search", this.searchText)
            }
        }, 750),

        search() {
            this.searchText.trim()
            this.$emit("search", this.searchText)
        },
        eraseSearch() {
            this.searchText = ""
            this.$emit("search", this.searchText)
        },
    },
    beforeUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";
.search {
    margin-right: 0.5em;
    margin-top: 0.4em;
    min-width: 200px;
    // width: 40%;
    min-width: 250px;
    max-width: 600px;
    position: relative;
    align-items: center;
    height: fit-content;

    input {
        width: 100%;
        padding: 1.2em 2em 1.2em 4em;
        background-color: $light-grey;
        border: none;
        border-radius: 1.1em;
        transition: all 200ms;

        &:focus {
            outline: none;
            box-shadow: inset 0 0 0 0.25rem rgba($blue-medium-pale, $alpha: 1);
            transition: all 200ms;
        }

        @media all and (max-width: 1024px) {
            padding: 1em 2em 1em 4em;
            border-radius: 0.8em;
        }
    }

    .search-icon {
        position: absolute;
        top: 0;
        left: 0.8em;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        div {
            width: 2.5em;
            height: 2.5em;
            border-radius: 0.6em;
            background-color: $orange-neutral;
            transition: all 200ms;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
                background-color: $orange-light;
                transition: all 200ms;
            }

            i {
                color: white;
            }
        }
    }

    .cross-icon {
        position: absolute;
        top: 0;
        right: 0.75em;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        div {
            width: 1em;
            height: 1em;

            transition: all 200ms;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: $blue-neutral;
                font-size: $verySmall;
                font-size: 1em;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
