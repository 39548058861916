<template>
    <div
        class="tooltip-component"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        customClass="tooltip-design"
        :title="text"
    >
        <slot></slot>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap"

export default {
    name: "Tooltip",
    props: {
        text: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            hover: false,
        }
    },

    mounted() {
        this.tooltipInstance = new Tooltip(this.$el, { trigger: "hover" })
    },

    beforeUnmount() {
        if (this.tooltipInstance) {
            this.tooltipInstance.dispose()
        }
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

.tooltip-component {
    position: relative;
    display: inline-block;

    .azerty {
        visibility: hidden;
        position: absolute;
        bottom: 160%;
        left: -5.2em;
        padding: 0.2em 0.5em;
        transform: translateX(-25%);
        width: max-content;
        z-index: 1;
        background-color: $white;
        color: $blue-neutral;
        text-align: center;
        padding: 5px 15px;
        border-radius: 5px;
        box-shadow: 0px 0 15px 0 rgb(0 0 0 / 20%);
        font-family: $font-avenir-roman;
        font-size: $small;
    }
    .azerty::after {
        content: " ";
        position: absolute;
        top: 100%;
        left: 50%;
        border-style: solid;
        border-width: 5px;
        border-color: $white transparent transparent transparent;
    }
}

.tooltip-component:hover {
    .azerty {
        visibility: visible;
    }
}

.tooltip {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0px 0 15px 0 rgb(0 0 0 / 20%);
}

.tooltip-inner {
    background-color: transparent;
    color: $blue-neutral;
    font-size: small;
    font-family: $font-avenir-roman;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-top-color: $white !important;
}
</style>
